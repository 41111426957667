<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.bm_projekodu }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="projeAdi">Proje Adı <span style="color:red">*</span></label>
								<InputText id="projeAdi" type="text" v-model="mainData.bm_name" :disabled="isDisabled"/>
								<span v-if="v$.mainData.bm_name.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_name.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.bm_firmaId" ref="entity_firma" label="Firma" entityName="account" nameField="name" :state="true" :required="true"
								@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null" ></EntityLookup>
								<span v-if="v$.mainData.bm_firmaId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_firmaId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="firmayetkilisi" v-model="mainData.bm_firmayetkilisiId" ref="entity_firmayetkilisi"
									label="Firma Yetkilisi" entityName="contact" nameField="fullname" :state="true"  :required="true"
									parentFieldName="parentcustomerid" :parentId="firmaSelected"
									@itemSelected="firmayetkilisiSelected = $event" @itemCleared="firmayetkilisiSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_firmayetkilisiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_firmayetkilisiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="projeTemsilcisi" v-model="mainData.bm_projetemsilcisiId" ref="entity_projetemsilcisi" label="Satış Temsilcisi" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true"
								@itemSelected="projeTemsilcisiSelected = $event" @itemCleared="projeTemsilcisiSelected = null" ></EntityLookup>
								<span v-if="v$.mainData.bm_projetemsilcisiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_projetemsilcisiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>TARİH BİLGİLERİ</h5>
					<div class="grid">
						<div class="col">
							<div class="field p-fluid">
								<label for="baslangicTarihi">Başlangıç Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="baslangicTarihi" v-model="mainData.bm_baslangictarihi" autocomplete="off" :showIcon="true" />
								<span v-if="v$.mainData.bm_baslangictarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_baslangictarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="bitisTarihi">Bitiş Tarihi</label>
								<Calendar showButtonBar id="bitisTarihi" v-model="mainData.bm_bitistarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="xGunOnceHatirlat">x Gün Önce Hatırlat</label>
								<Dropdown id="xGunOnceHatirlat" v-model="mainData.bm_xgunoncehatirlat" :options="SM_xGunOnceHatirlat" optionLabel="Value" optionValue="AttributeValue" placeholder="x Gün Önce Hatırlat" :showClear="true" />
							</div>
						</div>
						<div class="col" v-if="false">
							<div class="field p-fluid">
								<label for="projeilerlemedurumu">Proje İlerleme Durumu</label>
								<Dropdown id="projeilerlemedurumu" v-model="mainData.bm_projeilerlemedurumu" :options="SM_bm_projeilerlemedurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Proje İlerleme Durumu" :showClear="true" />
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="bm_projedurumu">Proje Durumu <span style="color:red">*</span></label>
								<Dropdown id="bm_projedurumu" v-model="mainData.bm_projedurumu" :options="SM_bm_projedurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Proje Durumu" :showClear="true" />
								<span v-if="v$.mainData.bm_projedurumu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_projedurumu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col">
							<div class="field p-fluid">
								<label for="adet">Adet <span style="color:red">*</span></label>
								<InputNumber id="adet" v-model="mainData.bm_adet" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="1" :showButtons="true"  />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>İLERLEME BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<div>
									<strong>Satış Ödeme: %{{ozetData.SatisIlerleme}}</strong> <Badge class="ml-3 mb-2" :value="ozetData.SatisTerminTarihi" severity="danger"></Badge>
								</div>
								
								<ProgressBar :value="ozetData.SatisIlerleme" :showValue="false"></ProgressBar>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<strong>Tasarım: %{{ozetData.TasarimIlerleme}}</strong> <Badge class="ml-3 mb-2" :value="ozetData.TasarimTerminTarihi" severity="danger"></Badge>
								<ProgressBar :value="ozetData.TasarimIlerleme" :showValue="false"></ProgressBar>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<strong>İmalat: %{{ozetData.ImalatIlerleme}}</strong> <Badge class="ml-3 mb-2" :value="ozetData.ImalatBitisTarihi" severity="danger"></Badge>
								<ProgressBar :value="ozetData.ImalatIlerleme" :showValue="false"></ProgressBar>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<strong>Satın Alma: %{{ozetData.SatinAlmaIlerleme}}</strong> <Badge class="ml-3 mb-2" :value="ozetData.SatinAlmaTerminTarihi" severity="danger"></Badge>
								<ProgressBar :value="ozetData.SatinAlmaIlerleme" :showValue="false"></ProgressBar>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<strong>Kurulum: %{{ozetData.KurulumIlerleme}}</strong> <Badge class="ml-3 mb-2" :value="ozetData.KurulumTeslimTarihi" severity="danger"></Badge>
								<ProgressBar :value="ozetData.KurulumIlerleme" :showValue="false"></ProgressBar>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="grid">
			<div class="col-12">
				<div class="card">
					<TabView :scrollable="true" lazy>
						<!-- TEKLİF -->
						<TabPanel>
							<template #header>
								<div class="cyan-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-shopping-cart"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">TEKLİF</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.TeklifAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.TeklifAdetKapali}} Kapalı Teklif</span>
										</div>
									</div>
								</div>
							</template>
							
							<CrmDataTable :key="CrmDataTable_bm_otomasyonteklif_key" baseEntityName="bm_otomasyonteklif" :options="CrmDataTable_bm_otomasyonteklif_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" :isEntityImageVisible="false" :isNewButtonVisible="true" yetkiEntityName="bm_otomasyonprojesi" />
						</TabPanel>
						
						<!-- SATIŞ -->
						<TabPanel>
							<template #header>
								<div class="pink-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-dollar"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SATIŞ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.SatisAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.SatisAdetKapali}} Kapalı Satış</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_bm_otomasyonsatis_key" baseEntityName="bm_otomasyonsatis" :options="CrmDataTable_bm_otomasyonsatis_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" :isEntityImageVisible="false" yetkiEntityName="bm_otomasyonprojesi" />
						</TabPanel>

						<!-- TASARIM -->
						<TabPanel>
							<template #header>
								<div class="green-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-dollar"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">TASARIM</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.TasarimAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.TasarımAdetKapali}} Kapalı Tasarım</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_bm_otomasyontasarim_key" baseEntityName="bm_otomasyontasarim" :options="CrmDataTable_bm_otomasyontasarim_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" :isEntityImageVisible="false" :isNewButtonVisible="true" yetkiEntityName="bm_otomasyonprojesi" />
						</TabPanel>

						<!-- İMALAT -->
						<TabPanel>
							<template #header>
								<div class="deeporange-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-users"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">İMALAT</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.ImalatAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.ImalatAdetKapali}} Kapalı İmalat</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_bm_otomasyonimalat_key" baseEntityName="bm_otomasyonimalat" :options="CrmDataTable_bm_otomasyonimalat_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" :isEntityImageVisible="false" :isNewButtonVisible="true" yetkiEntityName="bm_otomasyonprojesi" />
						</TabPanel>

						<!-- EKİPMAN TALEBİ -->
						<TabPanel>
							<template #header>
								<div class="purple-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">EKİPMAN TALEBİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.EkipmanTalebiAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.EkipmanTalebiAdetKapali}} Kapalı Ekipman Talebi</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_bm_otomasyonekipmantalebi_key" baseEntityName="bm_otomasyonekipmantalebi" :options="CrmDataTable_bm_otomasyonekipmantalebi_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" :isEntityImageVisible="false" :isNewButtonVisible="true" yetkiEntityName="bm_otomasyonprojesi" :isOzelIslem="true" ozelIslemButtonAdi="Satın Almaya Aktar" @onOzelIslem="onOzelIslemm" />
						</TabPanel>

						<!-- SATIN ALMA -->
						<TabPanel>
							<template #header>
								<div class="yellow-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">SATIN ALMA</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.SatinAlmaAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.SatinAlmaAdetKapali}} Kapalı Satın Alma</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_bm_otomasyonsatinalma_key" baseEntityName="bm_otomasyonsatinalma" :options="CrmDataTable_bm_otomasyonsatinalma_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" :isEntityImageVisible="false" :isNewButtonVisible="true" yetkiEntityName="bm_otomasyonprojesi" />
						</TabPanel>

						<!-- KURULUM DEVREYE ALMA -->
						<TabPanel>
							<template #header>
								<div class="pink-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-exclamation-triangle"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">KURULUM</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ozetData.KurulumAdet}} adet</span>
											<span class="overview-status p-1 fs-small">{{ozetData.KurulumAdetKapali}} Kapalı Kurulum</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_bm_otomasyonkurulum_key" baseEntityName="bm_otomasyonkurulum" :options="CrmDataTable_bm_otomasyonkurulum_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" :isEntityImageVisible="false" :isNewButtonVisible="true" yetkiEntityName="bm_otomasyonprojesi" />
						</TabPanel>
					</TabView>
				</div>
			</div>
			
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10045,
			ENTITY_NAME: 'bm_otomasyonprojesi',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},
			ozetData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
				{
					label: 'Devre Dışı Bırak',
					icon: 'pi pi-lock',
					command: () => { this.OnDevreDisiBirak(); },
					visible: () => this.mainData.statecode == 0
				},
				{
					label: 'Etkinleştir',
					icon: 'pi pi-lock-open',
					command: () => { this.OnEtkinlestir(); },
					visible: () => this.mainData.statecode == 1
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label: 'Satın Alma Teslim Listesi Yazdır',
					icon: 'pi pi-fw pi-print',
					command: () => { this.OnSatinAlmaListesiYazdir(); },
				},
				{
					label: 'Proje Ekipman Kontrol Listesi Yazdır',
					icon: 'pi pi-fw pi-print',
					command: () => { this.OnProjeEkipmanKontrolListesiYazdir(); },
				},
			],

			CrmDataTable_bm_otomasyonteklif_key: 0,
			CrmDataTable_bm_otomasyonteklif_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_otomasyonprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '07AE2E8C-B916-4232-B8FD-B92CF2ECC75B',
				preventSystemViews: false,
				preventUserViews: true
			},

			CrmDataTable_bm_otomasyonsatis_key: 0,
			CrmDataTable_bm_otomasyonsatis_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_otomasyonprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '2FDC1B93-7A30-48AC-9FF7-7F7B0BFA0B20',
				preventSystemViews: false,
				preventUserViews: true
			},
			
			CrmDataTable_bm_otomasyontasarim_key: 0,
			CrmDataTable_bm_otomasyontasarim_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_otomasyonprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '746CA258-7BB2-4FF2-9B35-13100C2EB169',
				preventSystemViews: false,
				preventUserViews: true
			},
			
			CrmDataTable_bm_otomasyonimalat_key: 0,
			CrmDataTable_bm_otomasyonimalat_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_otomasyonprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '9AFFB446-A62C-4074-BF3C-C5AC6FBD62EA',
				preventSystemViews: false,
				preventUserViews: true
			},
			
			CrmDataTable_bm_otomasyonekipmantalebi_key: 0,
			CrmDataTable_bm_otomasyonekipmantalebi_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_otomasyonprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: 'B03524B8-7AD8-4B9D-B112-B1B22EF5FA30',
				preventSystemViews: false,
				preventUserViews: true
			},
			
			CrmDataTable_bm_otomasyonsatinalma_key: 0,
			CrmDataTable_bm_otomasyonsatinalma_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_otomasyonprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: 'E45B3C2F-E952-42E6-ACBF-A4F63BADE3D4',
				preventSystemViews: false,
				preventUserViews: true
			},
			
			CrmDataTable_bm_otomasyonkurulum_key: 0,
			CrmDataTable_bm_otomasyonkurulum_options: {
				searchAttributes: ["bm_name"],
				filterParent: [
					{ 'name': 'bm_otomasyonprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: 'FDF8904B-429C-49C8-844B-E38CD455898C',
				preventSystemViews: false,
				preventUserViews: true
			},
			
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);
		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_xGunOnceHatirlat: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_xgunoncehatirlat');
		},
		SM_bm_projedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_projedurumu');
		},
		SM_bm_projeilerlemedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_projeilerlemedurumu');
		},
		firmaSelected: {
			get: function () {
				debugger;
				if (this.mainData["bm_firmaId"]) {
					return { "Value": this.mainData["bm_firmaId"], "Name": this.mainData["bm_firmaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				debugger;
				console.log(newValue);

				if (newValue == null) {
					this.mainData["bm_firmaId"] = null;
					this.mainData["bm_firmaName"] = null;
				}
				else {
					this.mainData["bm_firmaId"] = newValue.Value;
					this.mainData["bm_firmaName"] = newValue.Name;
				}
			}
		},
		firmayetkilisiSelected: {
			get: function () {
				if (this.mainData["bm_firmayetkilisiId"]) {
					return { "Value": this.mainData["bm_firmayetkilisiId"], "Name": this.mainData["bm_firmayetkilisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_firmayetkilisiId"] = null;
					this.mainData["bm_firmayetkilisiName"] = null;
				}
				else {
					this.mainData["bm_firmayetkilisiId"] = newValue.Value;
					this.mainData["bm_firmayetkilisiName"] = newValue.Name;
				}
			}
		},
		projeTemsilcisiSelected: {
			get: function () {
				if (this.mainData["bm_projetemsilcisiId"]) {
					return { "Value": this.mainData["bm_projetemsilcisiId"], "Name": this.mainData["bm_projetemsilcisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["bm_projetemsilcisiId"] = null;
					this.mainData["bm_projetemsilcisiName"] = null;
				}
				else {
					this.mainData["bm_projetemsilcisiId"] = newValue.Value;
					this.mainData["bm_projetemsilcisiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getOtomasyonProjesiById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.ozetData = data.ozet[0];

						const pageHistoryItem = { entityLetter: 'Otms', entityName: this.ENTITY_NAME, ID: this.mainData.entityId, title: this.mainData.bm_projekodu + '\r\n\r\n' + this.mainData.bm_firmaName };
						this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

						if (this.mainData["bm_firmaId"]) {
							this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["bm_firmaId"], "Name": this.mainData["bm_firmaName"] });
						}

						if (this.mainData["bm_firmayetkilisiId"]) {
							this.$refs.entity_firmayetkilisi.setDefaultData({ "Value": this.mainData["bm_firmayetkilisiId"], "Name": this.mainData["bm_firmayetkilisiName"] });
						}
						
						if (this.mainData["bm_projetemsilcisiId"]) {
							this.$refs.entity_projetemsilcisi.setDefaultData({ "Value": this.mainData["bm_projetemsilcisiId"], "Name": this.mainData["bm_projetemsilcisiName"] });
						}

						if (this.mainData["bm_baslangictarihi"]) {
							this.mainData["bm_baslangictarihi"] = new Date(this.mainData.bm_baslangictarihi);
						}

						if (this.mainData["bm_bitistarihi"]) {
							this.mainData["bm_bitistarihi"] = new Date(this.mainData.bm_bitistarihi);
						}

						this.CrmDataTable_bm_otomasyonteklif_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyonteklif_key++;

						this.CrmDataTable_bm_otomasyonsatis_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyonsatis_key++;

						this.CrmDataTable_bm_otomasyontasarim_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyontasarim_key++;

						this.CrmDataTable_bm_otomasyonimalat_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyonimalat_key++;

						this.CrmDataTable_bm_otomasyonekipmantalebi_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyonekipmantalebi_key++;

						this.CrmDataTable_bm_otomasyonsatinalma_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyonsatinalma_key++;

						this.CrmDataTable_bm_otomasyonkurulum_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_otomasyonkurulum_key++;
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getOtomasyonProjesiUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem(this.ENTITY_NAME, this.entityId);
        },
		async onOzelIslemm(data){
			
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});


			try {
				const postData = {
					ekipmanListesi: data,
					otomasyonProjesi : this.entityId
				}

				debugger;
				
				const response = await this.crmService.getOtomasyonSatinalmayaAktar(postData);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla AKTARILDI', life: 3000});
						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}
			finally {
				loader.hide();
			}
		},
		OnTeklifEkle(){
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_otomasyonteklifnew', params: { id: this.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		OnSatinAlmaListesiYazdir(){
            const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=OtomasyonSatinAlmaTeslimListesi&guid=" + this.entityId;
            window.open(reportUrl, "qqqq", "_blank");
        },
		OnProjeEkipmanKontrolListesiYazdir(){
            const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=OtomasyonProjeEkipmanListesi&guid=" + this.entityId;
            window.open(reportUrl, "qqq1", "_blank");
        },
		OnDevreDisiBirak() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		OnEtkinlestir() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 0,
				statusCode: 1
			};

			this.yenidenEtkinlestirItem(postData);
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_name: {
					required : helpers.withMessage('Adı alanı dolu olmalıdır!', required),
				},
				bm_firmaId: {
					required : helpers.withMessage('Firma alanı dolu olmalıdır!', required),
				},
				bm_firmayetkilisiId: {
					required : helpers.withMessage('Firma Yetkilisi alanı dolu olmalıdır!', required),
				},
				bm_projetemsilcisiId: {
					required : helpers.withMessage('Proje Temsilcisi alanı dolu olmalıdır!', required),
				},
				bm_baslangictarihi: {
					required : helpers.withMessage('Başlangıç Tarihi alanı dolu olmalıdır!', required),
				},
				bm_projedurumu: {
					required : helpers.withMessage('Proje Durumu alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}

::v-deep(.p-progressbar) {
	height: 10px;
}

::v-deep(.p-progressbar-label) {
	margin-left: 20px;
}

::v-deep(.p-progressbar-determinate .p-progressbar-value) {
	justify-content: left;
}


</style>
